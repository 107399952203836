import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import InfoIcon from "@material-ui/icons/Info";
import blue from "@material-ui/core/colors/blue";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import red from "@material-ui/core/colors/red";

// import ParamAreas from "./SystemParameter/ParamAreas";
// import IconButton from "@material-ui/core/IconButton";
// import LocationIcon from "@material-ui/icons/LocationOn";

// import MapParamTable from "./MapParamTable";

import moment from "moment";

const MOMENT_FORMAT = "MMMM Do YYYY";

const styles = (theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  col1: {
    fontWeight: "bold",
  },
});

function RowItem({ p, handleHistory }) {
  const [visible, setVisible] = React.useState(true);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const { features } = p.areas || [];
  return (
    <TableRow>
      <TableCell>
        <Tooltip title={`${JSON.stringify(p.extra)}`} placement="top">
          <InfoIcon style={{ color: blue[500] }} />
        </Tooltip>
      </TableCell>
      <TableCell>{visible && p.system_parameter.value_name}</TableCell>
      <TableCell>
        <div>{visible && p.quantitative}</div>
        <div>
          {p.qualitative !== null && p.qualitative.map((q) => q).join()}
        </div>
      </TableCell>
      <TableCell>{visible && moment(p.onset).format(MOMENT_FORMAT)}</TableCell>
      <TableCell>
        {visible && moment(p.expires).format(MOMENT_FORMAT)}
      </TableCell>
      <TableCell>
        {visible && moment(p.effective).format(MOMENT_FORMAT)}
      </TableCell>
      <TableCell style={{ maxWidth: 200 }}>
        {visible && p.description}
      </TableCell>
      <TableCell>
        {features
          .map((f) => {
            const pCountry = f.properties.country.name;
            let pRegion = "region not found";
            try {
              pRegion = f.properties.region.name;
            } catch {}
            return `[${pCountry},${pRegion}]`;
          })
          .join(`, `)}
      </TableCell>
      <TableCell>
        {confirmDelete && (
          <div>
            <div>Confirm?</div>
            <Button onClick={() => deleteRow()}>yes</Button>
            <Button onClick={() => setConfirmDelete(false)}>no</Button>
          </div>
        )}
        {visible ? (
          <Tooltip title="delete params">
            <DeleteForeverIcon
              onClick={() => setConfirmDelete(true)}
              style={{ color: red[700] }}
            />
          </Tooltip>
        ) : (
          "moved to history"
        )}
      </TableCell>
    </TableRow>
  );

  function deleteRow() {
    setVisible(false);
    setConfirmDelete(false);
    handleHistory(p);
  }
}

class SystemParameterTable extends React.Component {
  render() {
    const { info } = this.props;
    const parameters = [...info.parameters];

    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2">Parameters</Typography>
        </ExpansionPanelSummary>
        <AccordionDetails>
          <Paper style={{ width: "100%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>onset</TableCell>
                  <TableCell>expires</TableCell>
                  <TableCell>effective</TableCell>
                  <TableCell>description</TableCell>
                  <TableCell>area</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parameters.map((p, idx) => (
                  <RowItem
                    key={`params-${idx}`}
                    p={p}
                    handleHistory={this.props.handleHistory}
                  />
                ))}
              </TableBody>
            </Table>
          </Paper>
        </AccordionDetails>
      </ExpansionPanel>
    );
  }
}

SystemParameterTable.propTypes = {
  classes: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
};

export default withStyles(styles)(SystemParameterTable);
